import React, { useEffect, useState } from "react";
import "./investTab.scss";
import { NavLink, useLocation, useHistory } from "react-router-dom";

const tabData = [
  {
    id: 1,
    title: "Corporate Governance",
    link: "/investor-relations/corporate-governance#corporate",
  },
  {
    id: 2,
    title: "Financial Performance & Presentation",
    link: "/investor-relations/financial-performance#financial",
  },
  // {
  //   id: 3,
  //   title: "Annual Reports",
  //   link: "/investor-relations/annual-reports#annual",
  // },
  {
    id: 4,
    title: "Announcements",
    link: "/investor-relations/announcements#announcement",
  },
  {
    id: 5,
    title: "Shareholding Pattern",
    link: "/investor-relations/shareholding-pattern#shareholding",
  },
  {
    id: 6,
    title: "Intimation of Investor Meet",
    link: "/investor-relations/investor-meet-presentation#intimation",
  },
  {
    id: 7,
    title: "Investor Contacts",
    link: "/investor-relations/investor-contacts#investor",
  },
  {
    id: 8,
    title:
      "Disclosures under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    link: "/investor-relations/disclosures-under-regulation-46-and-62-of-sebi-lodr-regulations-2015#disclosure",
  },
];

const InvestTab = ({ scrollId }) => {
  const location = useLocation();
  const history = useHistory();

  const tabList = tabData.map((tab, i) => (
    <NavLink
      //   className={`tab_name ${(tab) => (tab.isActive ? "active" : "")}`}
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
      // onClick={scrollId ? scrollId : ""}
    >
      {tab.title}
    </NavLink>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          const offset = 100; // Gap from viewport top
          const targetPosition =
            targetSection.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
          // targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
        // history.replace("/investor-relations/investor-meet-presentation");
        history.replace(location.pathname);
      }
    }
  }, [location]);

  useEffect(()=>{
    
  })

  return (
    <>
      <div className="invest_tab_wrapper">{tabList}</div>
    </>
  );
};

export default InvestTab;
