import React, { useEffect } from "react";
import "./disclosures.scss";
import { file31, InvestorBgImg, InvestorFgImg, textilesProspectus } from "../../images";
import { Link } from "react-router-dom";
import Dash from "../../components/Dash/Dash";
import InvestAcc from "../../components/InvestAcc/InvestAcc";
import InvestTab from "../../components/InvestTab/InvestTab";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const tabTopData = [
  {
    id: 1,
    title: "Corporate Governance",
    link: "/investor-relations/corporate-governance#corporate",
  },
  {
    id: 2,
    title: "Financial Performance & Presentation",
    link: "/investor-relations/financial-performance#financial",
  },
   // {
  //   id: 3,
  //   title: "Annual Reports",
  //   link: "/investor-relations/annual-reports#annual",
  // },
  {
    id: 4,
    title: "Announcements",
    link: "/investor-relations/announcements#announcement",
  },
  {
    id: 5,
    title: "Shareholding Pattern",
    link: "/investor-relations/shareholding-pattern#shareholding",
  },
  {
    id: 6,
    title: "Intimation of Investor Meet",
    link: "/investor-relations/investor-meet-presentation#intimation",
  },
  {
    id: 7,
    title: "Investor Contacts",
    link: "/investor-relations/investor-contacts#investor",
  },
  {
    id: 8,
    title:
      "Disclosures under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    link: "/investor-relations/disclosures-under-regulation-46-and-62-of-sebi-lodr-regulations-2015#disclosure",
  },
];

const disclosureData = [
  {
    id: 1,
    mainTitle: "Disclosures Under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    data: [
      {
        title: "Memorandum of Association and Articles of Association",
        link: file31,
      },
    ],
  },
  // {
  //   id: 2,
  //   mainTitle: "Universal Texturisers Private Limited (Subsidiary)",
  //   data: [
  //     {
  //       title: "STL Financials 2024",
  //       link: textilesProspectus,
  //     },
  //     {
  //       title: "STL Financials 2023",
  //       link: textilesProspectus,
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   mainTitle: "Sanathan Polycot Private Limited (Subsidiary)",
  //   data: [
  //     {
  //       title: "STL Financials 2023",
  //       link: textilesProspectus,
  //     },
  //     {
  //       title: "STL Financials 2022",
  //       link: textilesProspectus,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   mainTitle: "Vajubhai Investments Private Limited (Group Company)",
  //   data: [
  //     {
  //       title: "STL Financials 2022",
  //       link: textilesProspectus,
  //     },
  //     {
  //       title: "STL Financials 2021",
  //       link: textilesProspectus,
  //     },
  //   ],
  // },
];

const Disclosures = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();

  const tabListTop = tabTopData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          const offset = 100; // Gap from viewport top
          const targetPosition =
            targetSection.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
          // targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
        // history.replace("/investor-relations/investor-meet-presentation");
        history.replace(location.pathname);
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Disclosures Under Regulation 46 & 62 of SEBI (LODR) Regulations, 2015"
        metaDesc="Review Sanathan’s comprehensive disclosures under Regulation 46 & 62 of SEBI (LODR) Regulations, 2015, ensuring transparency and compliance for investors and stakeholders."
      />

      <section className="disclosure_sec1 investor_relations">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Investor Relations
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Investor Relations</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3></h3> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InvestorBgImg} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
          {/* mobile section for image*/}
          <div className="mobile_section">
            <figure className="mob_bg_img">
              <img
                className="mob_img1"
                src={InvestorBgImg}
                alt="sanathan img"
              />
            </figure>
            <figure className="mob_fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="disclosure_sec2" id="disclosure">
        <div className="wrapper">
          <div className="row invest_row">
            <div className="col-md-4 invest_col">
              {width > 767 ? (
                <InvestTab />
              ) : (
                <div className="invest_tab_wrapper">{tabListTop}</div>
              )}
            </div>
            <div className="col-md-8 invest_col">
              <h2 className="heading">
                Disclosures Under Regulation 46 And 62 Of SEBI (LODR)
                Regulations, 2015
              </h2>
              <InvestAcc
                accData={disclosureData}
                dwnIcon={width > 767 ? false : true}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Disclosures;
