import React from "react";
import "./annualReportPdf.scss";

const AnnualReportPdf = ({ annualData }) => {
  return (
    <>
      <div className="row annual_rep_row">
        {annualData.map((data, i) => (
          <div className="col-lg-4 col-md-6 annual_rep_col" key={`report-${i}`}>
            <a href={data.link} className="annual_data_box" target="_blank" rel="noreferrer">
              <img
                src={data.img}
                alt={`${data.title} pdf`}
                className="pdf_img"
              />
              <h3 className="title">{data.title}</h3>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default AnnualReportPdf;
