import React from "react";
import "./investAcc.scss";
import Accordion from "react-bootstrap/Accordion";
import { investDwnIcon, investPdfIcon, investUpArrow } from "../../images";

const InvestAcc = ({ accData, dwnIcon, firstNotActive }) => {
  return (
    <div className="invest_acc_container">
      <Accordion defaultActiveKey="0" className="invest_acc">
        {accData.map((item, i) => (
          <Accordion.Item eventKey={firstNotActive ? i : i + ""} key={i}>
            <Accordion.Header>
              <span>{item.mainTitle}</span>
              <img src={investUpArrow} alt="arrow" className="arrow" />
            </Accordion.Header>

            <Accordion.Body>
              {item?.data?.map((data, index) => (
                <div className="pdf_container" key={index}>
                  <div className={`pdf_text_wrapper`}>
                    <img
                      src={investPdfIcon}
                      alt="pdf icon"
                      className="pdf_icon"
                    />
                    <h4 className="pdf_title">{data.title}</h4>
                  </div>
                  {dwnIcon ? (
                    <a href={data.link} download className={`pdf_link`}>
                      <img
                        src={investDwnIcon}
                        alt="download"
                        className="dwn_icon"
                      />
                    </a>
                  ) : (
                    <a href={data.link} download className="pdf_link">
                      Download
                    </a>
                  )}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default InvestAcc;
