import React, { useEffect } from "react";
import "./investorRelationsV2.scss";
import {
  file10,
  file11,
  file12,
  file13,
  file14,
  file15,
  file16,
  file17,
  file18,
  file19,
  file20,
  file21,
  file22,
  file23,
  file24,
  file25,
  file26,
  file27,
  InvestorBgImg,
  InvestorFgImg,
  textilesProspectus,
} from "../../images";
import { Link } from "react-router-dom";
import Dash from "../../components/Dash/Dash";
import InvestAcc from "../../components/InvestAcc/InvestAcc";
import InvestTab from "../../components/InvestTab/InvestTab";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const tabTopData = [
  {
    id: 1,
    title: "Corporate Governance",
    link: "/investor-relations/corporate-governance#corporate",
  },
];

const tabBottomData = [
  {
    id: 2,
    title: "Financial Performance & Presentation",
    link: "/investor-relations/financial-performance#financial",
  },
  // {
  //   id: 3,
  //   title: "Annual Reports",
  //   link: "/investor-relations/annual-reports#annual",
  // },
  {
    id: 4,
    title: "Announcements",
    link: "/investor-relations/announcements#announcement",
  },
  {
    id: 5,
    title: "Shareholding Pattern",
    link: "/investor-relations/shareholding-pattern#shareholding",
  },
  {
    id: 6,
    title: "Intimation of Investor Meet",
    link: "/investor-relations/investor-meet-presentation#intimation",
  },
  {
    id: 7,
    title: "Investor Contacts",
    link: "/investor-relations/investor-contacts#investor",
  },
  {
    id: 8,
    title:
      "Disclosures under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    link: "/investor-relations/disclosures-under-regulation-46-and-62-of-sebi-lodr-regulations-2015#disclosure",
  },
];

const corporateGovernance = [
  {
    id: 1,
    mainTitle: "Company Policy",
    data: [
      {
        title: "Board Evaluation Policy",
        link: file10,
      },
      {
        title: "Archival Policy",
        link: file11,
      },
      {
        title: "COC for Directors and Senior Management",
        link: file12,
      },
      {
        title: "Material Subsidiaries Policy",
        link: file13,
      },
      {
        title: "Materiality Policy",
        link: file14,
      },
      {
        title: "Nomination and Remuneration Policy",
        link: file15,
      },
      {
        title: "Policy on Board Diversity",
        link: file16,
      },
      {
        title:
          "Policy with respect to obligations of Directors and Senior Management",
        link: file17,
      },
      {
        title: "Related Party Transaction Policy",
        link: file18,
      },
      {
        title: "Risk Management Policy",
        link: file19,
      },
      {
        title: "Succession Policy",
        link: file20,
      },
      {
        title: "Vigil Mechanism",
        link: file21,
      },
      {
        title: "CSR Policy",
        link: file22,
      },
      {
        title: "Dividend Distribution Policy",
        link: file23,
      },
      {
        title: "Insider Trading Policy",
        link: file24,
      },
      {
        title:
          "Code of Practices and Procedures for Fair Disclosure of unpublished price sensitive information",
        link: file25,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Familiarization Programme",
    data: [
      {
        title: "Familiarization Programme for Independent Directors",
        link: file26,
      },
    ],
  },
  {
    id: 3,
    mainTitle: "Committees of Board",
    data: [
      {
        title: "Board Level Committees",
        link: file27,
      },
    ],
  },
];

const InvestorRelationsV2 = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();

  const tabListBottom = tabBottomData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  const tabListTop = tabTopData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          const offset = 100; // Gap from viewport top
          const targetPosition =
            targetSection.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
          // targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
        // history.replace("/investor-relations/investor-meet-presentation");
        history.replace(location.pathname);
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Investor Relations | Sanathan Textiles"
        metaDesc="View the latest financial information and updates with Sanathan Textiles' investor relations. Explore our reports, and performance insights for informed investment decisions."
      />

      <section className="invest_rel_sec1 investor_relations">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Investor Relations
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Investor Relations</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3></h3> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InvestorBgImg} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
          {/* mobile section for image*/}
          <div className="mobile_section">
            <figure className="mob_bg_img">
              <img
                className="mob_img1"
                src={InvestorBgImg}
                alt="sanathan img"
              />
            </figure>
            <figure className="mob_fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="invest_rel_sec2" id="corporate">
        <div className="wrapper">
          <div className="row invest_row">
            <div className="col-md-4 invest_col">
              {width > 767 ? (
                <InvestTab />
              ) : (
                <div className="invest_tab_wrapper">{tabListTop}</div>
              )}
            </div>
            <div className="col-md-8 invest_col">
              <h2 className="heading">Corporate Governance</h2>
              <InvestAcc
                accData={corporateGovernance}
                dwnIcon={width > 767 ? false : true}
                firstNotActive
              />
              {width <= 767 && (
                <div className="invest_tab_wrapper invest_tab_wrapper_2">
                  {tabListBottom}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestorRelationsV2;
