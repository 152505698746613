import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./cdp.scss";
import "../style.scss";

import {
  blueArrow,
  cdpDigitalPdf,
  cdphero,
  cdplogo,
  ethnicWear,
  fancyYarnCone,
  homeTextile,
  ladiesGarment,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const applicationData = [
  {
    id: 1,
    title: "Ladies Garment",
    img: ladiesGarment,
  },
  {
    id: 2,
    title: "Fancy Yarn Cone",
    img: fancyYarnCone,
  },
  {
    id: 3,
    title: "Ethnic Wear",
    img: ethnicWear,
  },
  {
    id: 4,
    title: "Home Textiles",
    img: homeTextile,
  },
];

const CDP = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const currentURL = window.location.href;

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  const applicationList = applicationData.map((item, i) => (
    <div className="col-lg-3 col-md-6 app_col" key={i}>
      <div className="app_box">
        <div className="img_wrapper">
          <img src={item.img} alt={item.title} className="app_img" />
        </div>
        <p className="heading">{item.title}</p>
      </div>
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={currentURL}
        metaTitle="Cationic Dyeable Polyester (CDP) - Sanathan Textiles"
        metaDesc="Cationic Dyeable Polyester (CDP) is a specialised polyester yarn that can be coloured with cationic dyes. It shows excellent colour development and fastness and hence CDP yarns enhances dyeability and results in more vibrant and long-lasting colours."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner cdp_products_inner">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            Products {">"} Cationic Dyeable Polyester
          </div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <img className="drycool_img" src={cdplogo} alt="sanathan img" />
            <p>
              Cationic Dyeable Polyester (CDP) is a specialised polyester yarn
              that can be coloured with cationic dyes. It shows excellent colour
              development and fastness, and hence CDP yarns enhance dyeability
              and result in more vibrant and long-lasting colours.
              <br />
              <br />
              Discover the innovation of Cationic Dyeable Polyester (CDP) yarn
              at Sanathan Textile. Our advanced CDP yarn offers vibrant colors,
              cost-effective dyeing, and unmatched versatility. Ideal for
              sportswear, home textiles, and fashion, explore Texturised, FDY,
              POY, and Air Tex CDP yarns tailored to meet evolving industry
              demands with sustainable excellence.
            </p>
            <div className="bottom_arrow_container broch_link_wrap">
              <a
                href={cdpDigitalPdf}
                target="_blank"
                rel="noreferrer"
                className="broch_link"
              >
                Download Brochure
                <img
                  className="right_arrow"
                  src={blueArrow}
                  alt="sanathan img"
                />
              </a>
            </div>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={cdphero} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="cdp_sec4">
        <div className="wrapper">
          <h3 className="prod_title">
            Product range available in: SD-CDP, BRT-CDP, CD Melange
          </h3>
          <div className="row">
            <div className="col-lg-4">
              <ul>
                <li>POY Denier from 60 to 500</li>
                <li>ATY Denier from 30 to 2000</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>DTY Denier from 50 to 600</li>
                <li>FDY Denier from 30 to 600</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="cdp_sec3">
        <div className="wrapper">
          <h3>Advantages:</h3>
          <div className="row">
            <div className="col-lg-4">
              <ul>
                <li>Excellent dye affinity</li>
                <li>
                  Exhibit superior resistance to colour fading when exposed to
                  water.
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>High luminosity</li>
                <li>
                  The anionic groups in CDP enhance dye absorption, leading to
                  better colour uniformity and depth.
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>
                  Excellent colourfastness (Ability to hold vibrant colours)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="cdp_sec5">
        <div className="wrapper">
          <h3 className="title">Application</h3>
          <div className="row app_row">{applicationList}</div>
          <div className="content_wrapper">
            <p className="app_desc">
              This value-added Yarn will contribute to the evolution of the
              textile industry, catering to the ever-evolving consumer
              preferences and market demands. The versatility of CDP yarn in
              fabric makes it ideal for various applications, from fashion and
              sportswear to home textiles and automotive fabrics. Blending CDP
              with Fibers like wool, Lycra, cotton, and silk creates luxurious
              fabrics with enhanced visual appeal. As demand for
              high-performance and aesthetically pleasing textiles grows, CDP
              and other value-added yarns will play a crucial role in meeting
              these needs.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CDP;
