import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./sflex.scss";
import "../style.scss";

import {
  blueArrow,
  indianLadies,
  knittedFabrics,
  ladiesLegging,
  sflexhero,
  sflexlogo,
  sFlexPdf,
  swimSuite,
  warpknitted,
  wovenFabrics,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const applicationData = [
  {
    id: 1,
    title: "Knitted Fabrics",
    img: knittedFabrics,
  },
  {
    id: 2,
    title: "Woven Fabrics",
    img: wovenFabrics,
  },
  {
    id: 3,
    title: "Warp Knitted Fabrics",
    img: warpknitted,
  },
  {
    id: 4,
    title: "Indian Ladies Garment",
    img: indianLadies,
  },
  {
    id: 5,
    title: "Swim Suit",
    img: swimSuite,
  },
  {
    id: 6,
    title: "Ladies Leggings",
    img: ladiesLegging,
  },
];

const SFlex = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const currentURL = window.location.href;

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  const applicationList = applicationData.map((item, i) => (
    <div className="col-lg-3 col-md-6 app_col" key={i}>
      <div className="app_box">
        <div className="img_wrapper">
          <img src={item.img} alt={item.title} className="app_img" />
        </div>
        <p className="heading">{item.title}</p>
      </div>
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={currentURL}
        metaTitle="S-flex is a groundbreaking self-stretch polyester filament yarn - Sanathan Textiles"
        metaDesc="S-flex offers four-way stretch capabilities without spandex. This means you can enjoy the same stretch and comfort as traditional stretch fabrics at an even lighter weight , with a more natural and breathable feel."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner">
        <div className="wrapper">
          <div className="breadcrumb_styles">Products {">"} S Flex</div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <img className="drycool_img" src={sflexlogo} alt="sanathan img" />
            <p>
              S-flex is a groundbreaking self-stretch polyester filament yarn.
              It offers four-way stretch capabilities without spandex. This
              means you can enjoy the same stretch and comfort as traditional
              stretch fabrics at an even lighter weight, with a more natural and
              breathable feel. This innovative yarn promises cost-effective,
              easy-to-handle fabric production with superior performance across
              knitted, woven and warp knitted fabrics.
              <br />
              <br />
              S-Flex, the advanced stretch yarn from Sanathan Textile, offers
              spandex-free, four-way stretch for exceptional comfort,
              durability, and sustainability. With a cotton-like appearance and
              enhanced properties like wrinkle resistance and dimensional
              stability, it creates lightweight, high-performance fabrics.
              Compatible with various constructions, S-Flex ensures
              eco-friendly, cost-effective, and long-lasting solutions.
            </p>
            <div className="bottom_arrow_container broch_link_wrap">
              <a
                href={sFlexPdf}
                target="_blank"
                rel="noreferrer"
                className="broch_link"
              >
                Download Brochure
                <img
                  className="right_arrow"
                  src={blueArrow}
                  alt="sanathan img"
                />
              </a>
            </div>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={sflexhero} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="sflex_sec3">
        <div className="wrapper">
          <h3>Advantages:</h3>
          <div className="row">
            <div className="col-lg-4">
              <ul>
                <li>Spandex-Free Four way Stretch</li>
                <li>Versatile Applications</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Cost-Effective Processing</li>
                <li>Dimensional Stability</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Enhanced Fabric Properties</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="sflex_sec4">
        <div className="wrapper">
          <h3 className="title">Versatile Applications of S-Flex</h3>
          <div className="row app_row">{applicationList}</div>
          <div className="content_wrapper">
            <p className="app_desc">
              <span className="head">THE FUTURE OF STRETCH FABRICS</span>
              S-flex represents a paradigm shift in textile manufacturing. It
              combines the benefits of polyester with unprecedented stretch
              capabilities. This innovative value added yarn opens new
              possibilities for designers and manufacturers alike. By
              eliminating the need for spandex, S-flex simplifies production
              processes. It also enhances fabric performance and longevity. The
              result is a versatile, cost-effective solution for the modern
              textile industry.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SFlex;
