import React, { useEffect } from "react";
import "./annualReports.scss";
import {
  dryCoolPdf,
  InvestorBgImg,
  InvestorFgImg,
  report01,
} from "../../images";
import { Link } from "react-router-dom";
import Dash from "../../components/Dash/Dash";
import InvestTab from "../../components/InvestTab/InvestTab";
import AnnualReportPdf from "../../components/AnnualReportPdf/AnnualReportPdf";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink, useLocation, useHistory } from "react-router-dom";

const tabTopData = [
  {
    id: 1,
    title: "Corporate Governance",
    link: "/investor-relations/corporate-governance#corporate",
  },
  {
    id: 2,
    title: "Financial Performance & Presentation",
    link: "/investor-relations/financial-performance#financial",
  },
   // {
  //   id: 3,
  //   title: "Annual Reports",
  //   link: "/investor-relations/annual-reports#annual",
  // },
];

const tabBottomData = [
  {
    id: 4,
    title: "Announcements",
    link: "/investor-relations/announcements#announcement",
  },
  {
    id: 5,
    title: "Shareholding Pattern",
    link: "/investor-relations/shareholding-pattern#shareholding",
  },
  {
    id: 6,
    title: "Intimation of Investor Meet",
    link: "/investor-relations/investor-meet-presentation#intimation",
  },
  {
    id: 7,
    title: "Investor Contacts",
    link: "/investor-relations/investor-contacts#investor",
  },
  {
    id: 8,
    title:
      "Disclosures under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    link: "/investor-relations/disclosures-under-regulation-46-and-62-of-sebi-lodr-regulations-2015#disclosure",
  },
];

const annualReportData = [
  {
    img: report01,
    title: "2023 - 2024",
    link: dryCoolPdf,
  },
  {
    img: report01,
    title: "2022 - 2023",
    link: dryCoolPdf,
  },
  {
    img: report01,
    title: "2021 - 2022",
    link: dryCoolPdf,
  },
  {
    img: report01,
    title: "2020 - 2021",
    link: dryCoolPdf,
  },
  {
    img: report01,
    title: "2019 - 2020",
    link: dryCoolPdf,
  },
  {
    img: report01,
    title: "2018 - 2019",
    link: dryCoolPdf,
  },
];

const AnnualReports = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();

  const tabListBottom = tabBottomData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  const tabListTop = tabTopData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

    useEffect(() => {
      const hash = window.location.hash;
      if (hash) {
        const targetId = hash.substring(1);
        const targetSection = document.getElementById(targetId);
        if (targetSection) {
          setTimeout(() => {
            const offset = 100; // Gap from viewport top
            const targetPosition =
              targetSection.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top: targetPosition, behavior: "smooth" });
            // targetSection.scrollIntoView({ behavior: "smooth" });
          }, 0);
          // history.replace("/investor-relations/investor-meet-presentation");
          history.replace(location.pathname);
        }
      }
    }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="annual_rep_sec1 investor_relations">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Investor Relations
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Investor Relations</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3></h3> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InvestorBgImg} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
          {/* mobile section for image*/}
          <div className="mobile_section">
            <figure className="mob_bg_img">
              <img
                className="mob_img1"
                src={InvestorBgImg}
                alt="sanathan img"
              />
            </figure>
            <figure className="mob_fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="annual_rep_sec2" id="annual">
        <div className="wrapper">
          <div className="row invest_row">
            <div className="col-md-4 invest_col">
              {width > 767 ? (
                <InvestTab />
              ) : (
                <div className="invest_tab_wrapper">{tabListTop}</div>
              )}
            </div>
            <div className="col-md-8 invest_col">
              <h2 className="heading">Annual Reports</h2>
              <AnnualReportPdf annualData={annualReportData} />
              {width <= 767 && (
                <div className="invest_tab_wrapper invest_tab_wrapper_2">
                  {tabListBottom}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnnualReports;
